import React from "react"
import { useAuth0 } from "@auth0/auth0-react";
import { navigate } from "gatsby";
const HandleAuth0Callback = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  React.useEffect(()=>{
      if (isLoading) return
      if (isAuthenticated && user) {
        navigate('/account');
      }
      if (!isAuthenticated) {
        navigate('/');
      }
    },[isLoading,isAuthenticated,user])

  return (
    <span></span>
  )
}


export default HandleAuth0Callback
